import React, { useState } from 'react';
import { Form, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DeleteOutlined, UserAddOutlined, CalendarOutlined } from '@ant-design/icons';

import { ModalEdit } from './Modal';
import { BlueFlag } from '@/assets/icons/action-plans/blue-flag';
import { useDeleteActionPlan } from '@/hooks/v2/useDeleteActionPlan';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsableOptions';
import { useBulkUpdateUpdateActionPlan } from '@/hooks/v2/useBulkUpdateUpdateActionPlan';
import { CardWrapper, StyledButton, StyledIconEdit, DANGER_COLOR } from './styles';

type ActionsProps = {
	id: React.Key[];
	companyId: string;
	organizationId: string;
	canChangeResponsible?: boolean;
};

type IconButtonProps = {
	color?: string;
	disabled?: boolean;
	onClick: () => void;
	icon: React.ElementType;
};

type EditType = 'priority' | 'due_date' | 'responsible_user_id' | 'delete' | null;

export function Actions({ id, organizationId, companyId, canChangeResponsible }: Readonly<ActionsProps>) {
	const { mutateAsync: deleteActionPlan } = useDeleteActionPlan();
	const { mutateAsync: updateActionPlan } = useBulkUpdateUpdateActionPlan();
	const {
		data: users,
		isError: hasErrorUsers,
		isLoading
	} = useGetResponsableOptions({
		organization_id: organizationId,
		company_id: companyId
	});

	const [form] = Form.useForm();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSelectEmpty, setIsSelectEmpty] = useState(true);
	const [selectedEditType, setSelectedEditType] = useState<EditType>(null);

	function toggleModal(editType: EditType = null): void {
		setIsModalOpen((prevState) => !prevState);
		setSelectedEditType(editType);
	}

	function handleModalClose(): void {
		form.resetFields();
		toggleModal();
	}

	async function handleSubmit(): Promise<void> {
		const values = await form.validateFields();
		await updateActionPlan({
			ids: id as string[],
			company_id: companyId,
			organization_id: organizationId,
			field: { name: selectedEditType, value: values[selectedEditType as string] }
		});
		handleModalClose();
	}

	async function handleDelete(): Promise<void> {
		await deleteActionPlan({ ids: id as string[], company_id: companyId, organization_id: organizationId });
		handleModalClose();
	}

	function handleSelectChange(value: string | undefined): void {
		setIsSelectEmpty(!value);
	}

	function IconButton({ icon: Icon, onClick, color, disabled }: IconButtonProps): JSX.Element {
		return (
			<StyledButton onClick={onClick} size="large" disabled={disabled}>
				<StyledIconEdit as={Icon} color={color} />
			</StyledButton>
		);
	}

	const editButtons = [
		{ type: 'priority', icon: BlueFlag },
		{ type: 'due_date', icon: CalendarOutlined },
		{ type: 'responsible_user_id', icon: UserAddOutlined, disabled: !canChangeResponsible },
		{ type: 'delete', icon: DeleteOutlined, color: DANGER_COLOR }
	];

	if (hasErrorUsers) {
		message.error(I18n.get('Error fetching responsible options'));
		return null;
	}

	return (
		<>
			<CardWrapper>
				{editButtons.map(({ type, icon, color, disabled }) => (
					<IconButton
						key={type}
						icon={icon}
						color={color}
						disabled={disabled}
						onClick={() => toggleModal(type as EditType)}
					/>
				))}
			</CardWrapper>
			<ModalEdit
				form={form}
				users={users || []}
				isLoading={isLoading}
				handleOk={toggleModal}
				isModalOpen={isModalOpen}
				selectType={selectedEditType}
				isSelectEmpty={isSelectEmpty}
				handleOnSubmit={handleSubmit}
				handleOnDelete={handleDelete}
				handleOnCancel={handleModalClose}
				handleSelectChange={handleSelectChange}
			/>
		</>
	);
}
