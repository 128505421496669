import React, { useState } from 'react';
import { Col, message, Row } from 'antd';
import { PaperClipOutlined, PlusOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { Title } from '@/components/Typography';
import { AttachmentsModal } from '@/components/ui/Modals/Attachments';
import { FilePreview } from '@/components/ui/Modals/Attachments/FilePreview';
import { StyledButton } from '@/components/ui/Modals/Attachments/FilePreview/styles';
import { getToolIcon } from '@/components/ui/Modals/Attachments/ErgonomicTools/utils';
import { ToolCard, ToolIcon, ToolTitle } from './styles';
import { useGetAttachments } from '@/hooks/v2/useGetAttachments';
import { useGetRelatedReports } from '@/hooks/v2/useGetRelatedReports';

const MAX_ATTACHMENTS = 4;

interface AttachmentsProps {
	companyId: string;
	actionPlanId: string;
	organizationId: string;
}

export function Attachments({ actionPlanId, companyId, organizationId }: Readonly<AttachmentsProps>) {
	const [isAttachmentsOpen, setIsAttachmentsOpen] = useState(false);

	const { data: attachments, error: attachmentsError } = useGetAttachments({
		company_id: companyId,
		action_plan_id: actionPlanId,
		organization_id: organizationId
	});

	const { data: relatedReports, error: reportsError } = useGetRelatedReports({
		company_id: companyId,
		action_plan_id: actionPlanId,
		organization_id: organizationId
	});

	const toggleAttachmentsModal = () => setIsAttachmentsOpen((prev) => !prev);

	const renderUploadButton = () => (
		<StyledButton onClick={toggleAttachmentsModal}>
			<PaperClipOutlined style={{ fontSize: '38px' }} />
			<div>
				<Title level={5}>Attach new file</Title>
			</div>
		</StyledButton>
	);

	const renderAddReportButton = () => (
		<ToolCard style={{ cursor: 'pointer' }} onClick={toggleAttachmentsModal}>
			<ToolIcon>
				<PlusOutlined style={{ fontSize: '20px' }} />
			</ToolIcon>
			<ToolTitle>Add report</ToolTitle>
		</ToolCard>
	);

	const renderCheckedReports = () => {
		if (!relatedReports) return null;

		return relatedReports
			.filter((report) => report.checked)
			.map((report) => (
				<Col key={report.report_id}>
					<ToolCard
						onClick={() =>
							window.open(
								`${window.location.origin}/reporting/${report.ergonomic_tool.description}/${report.report_id}`
							)
						}
					>
						<ToolIcon>{getToolIcon(report.ergonomic_tool.name)}</ToolIcon>
						<ToolTitle>{report.ergonomic_tool.title}</ToolTitle>
					</ToolCard>
				</Col>
			));
	};

	const handleError = () => {
		if (attachmentsError || reportsError) {
			message.error(I18n.get('Failed to load attachments'));
		}
	};

	handleError();

	const canAddMoreAttachments = attachments && attachments.length < MAX_ATTACHMENTS;
	const shouldShowAddReportButton = attachments && attachments.length >= MAX_ATTACHMENTS;

	return (
		<>
			<Row gutter={[16, 0]}>
				{canAddMoreAttachments && <Col style={{ minHeight: '200px' }}>{renderUploadButton()}</Col>}
				<Col flex="auto" hidden={!attachments || attachments.length === 0}>
					<FilePreview files={attachments || []} companyId={companyId} organizationId={organizationId} />
				</Col>
			</Row>

			{relatedReports && (
				<Row>
					{shouldShowAddReportButton && <Col>{renderAddReportButton()}</Col>}
					{renderCheckedReports()}
				</Row>
			)}

			<AttachmentsModal
				companyId={companyId}
				isOpen={isAttachmentsOpen}
				actionPlanId={actionPlanId}
				organizationId={organizationId}
				onCancel={toggleAttachmentsModal}
			/>
		</>
	);
}
