import React, { useRef, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import type { Key } from 'rc-tree/lib/interface';
import { Col, Row, Tree, TreeDataNode } from 'antd';

import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { PDFSections } from '@/types/entities/CustomReportResult';
import { Paragraph, Title } from '@/components/Typography';
import { FileDTO } from '@/components/ui/Inputs';
import { ModalBorder } from '@/components/ui';
import LoadingSuccess from './LoadingAnimation/LoadingSuccess';
import Loading from './LoadingAnimation/Loading';

type MultipleCheckbox =
	| Key[]
	| {
			checked: Key[];
			halfChecked: Key[];
	  };

interface PdfModalProps {
	isModalOpen: boolean;
	fileData: FileDTO;
	isLoadingPDF: boolean;
	onDownloadPDF: (file: FileDTO) => Promise<void>;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	initialValues?: PDFSections[];
	treeData?: TreeDataNode[];
}

export const PdfModal: React.FC<PdfModalProps> = ({
	isModalOpen,
	setIsModalOpen,
	fileData,
	onDownloadPDF,
	isLoadingPDF,
	initialValues: defaultData,
	treeData: defaultTreeData
}) => {
	const initialValues: PDFSections[] = [
		PDFSections.REBA,
		PDFSections.NIOSH,
		PDFSections.KIM_PP,
		PDFSections.KIM_MHO,
		PDFSections.ACTION_PLANS,
		PDFSections.STRAIN_INDEX,
		PDFSections.NOT_EVALUATED,
		PDFSections.LIBERTY_MUTUAL,
		PDFSections.CHARACTERISTICS,
		PDFSections.WORK_CONDITIONS
	];

	const [checkBox, setCheckBox] = useState<MultipleCheckbox>(defaultData || initialValues);
	const [showSuccess, setShowSuccess] = useState(isLoadingPDF);
	const timerRef = useRef<NodeJS.Timer>();

	function handleCancel(): void {
		setIsModalOpen(false);
	}

	function onCheck(checked: MultipleCheckbox): void {
		setCheckBox(checked);
	}

	async function handleOk(): Promise<void> {
		Promise.all([
			onDownloadPDF({ ...fileData, pdf_custom: checkBox }),
			new Promise(function (resolve) {
				setTimeout(() => {
					setShowSuccess(true);
					resolve(true);
				}, 100);
			})
		]);

		timerRef.current = setTimeout(() => {
			setShowSuccess(false);
		}, 2300);
	}

	const treeData: TreeDataNode[] = [
		{
			selectable: false,
			key: 'work_conditions',
			style: { fontWeight: 'bold' },
			title: I18n.get('Working conditions')
		},
		{
			selectable: false,
			key: 'characteristics',
			style: { fontWeight: 'bold' },
			title: I18n.get('Characteristics')
		},
		{
			selectable: false,
			key: 'not_evaluated',
			style: { fontWeight: 'bold' },
			title: I18n.get('Criteria not evaluated')
		},
		{
			title: I18n.get('Tools'),
			key: 'tools',
			selectable: false,
			style: { fontWeight: 'bold' },
			switcherIcon: <></>,
			children: [
				{
					selectable: false,
					key: 'niosh',
					title: I18n.get('Cargo Handling (NIOSH)')
				},
				{
					selectable: false,
					key: 'kim_mho',
					title: I18n.get('Manual Handling (KIM)')
				},
				{
					selectable: false,
					key: 'reba',
					title: I18n.get('Movement by score (Kinebot/JDS)')
				},
				{
					selectable: false,
					key: 'kim_pp',
					title: I18n.get('Push and Pull (KIM)')
				},
				{
					selectable: false,
					key: 'strain_index',
					title: I18n.get('Revised Strain Index (RSI)')
				},
				{
					selectable: false,
					key: 'liberty_mutual',
					title: I18n.get('Material Handling (Liberty Mutual)')
				}
			]
		},
		{
			selectable: false,
			key: 'action_plans',
			style: { fontWeight: 'bold' },
			title: I18n.get('Action plans')
		}
	];

	return (
		<ModalBorder $borderRadius="30px" open={isModalOpen} footer={false} onCancel={handleCancel}>
			<Row justify="center" gutter={[0, 10]} style={{ marginTop: '0.5rem' }} hidden={isLoadingPDF || showSuccess}>
				<Col style={{ textAlign: 'center' }} span={20}>
					<Title level={3}>PDF</Title>
					<Paragraph>Select the items you want to present in your document</Paragraph>
				</Col>
				<Col span={21}>
					<Tree
						checkable
						defaultExpandAll
						treeData={defaultTreeData || treeData}
						disabled={isLoadingPDF}
						defaultCheckedKeys={defaultData || initialValues}
						onCheck={(checked) => onCheck(checked)}
					/>
				</Col>
				<Col>
					<CustomButton size="large" onClick={handleCancel} style={{ margin: '1rem 2rem 0 0' }}>
						{I18n.get('Cancel')}
					</CustomButton>
					<CustomButton loading={isLoadingPDF} size="large" onClick={handleOk} type="primary">
						{I18n.get('Generate document')}
					</CustomButton>
				</Col>
			</Row>
			{isLoadingPDF && <Loading />}
			{!isLoadingPDF && showSuccess && <LoadingSuccess />}
		</ModalBorder>
	);
};
