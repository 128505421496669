import styled from 'styled-components';
import { Tooltip } from 'antd';

export const CustomTooltip = styled(Tooltip)`
	padding-left: 5px;

	.ant-tooltip {
		top: -60px !important;
		left: 15px !important;
	}

	.ant-tooltip-inner {
		width: 205px;
		color: #262626;
		background-color: #fffa;
		border: 1px solid #262626;
		backdrop-filter: blur(4px);
		border-radius: 10px 10px 10px 0;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}
`;
