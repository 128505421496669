import React from 'react';
import { getResultRPN, riskColors } from '@/utils/riskRange';
import * as S from './styles';

interface ScoreProps {
	value?: number;
}

export function Score({ value = 0 }: Readonly<ScoreProps>) {
	const RPN = getResultRPN(value);
	const color = riskColors[RPN];
	return <S.Score $color={color}>{`RPN: ${value || '-'}`}</S.Score>;
}
