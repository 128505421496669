import React from 'react';
import { useActionPlanContext } from '../context';
import * as S from './styles';

interface DrawerWrapperProps {
	children: React.ReactNode;
}

export function DrawerWrapper({ children }: Readonly<DrawerWrapperProps>) {
	const { isNotificationsOpen, onToggleNotifications } = useActionPlanContext();
	return (
		<S.DrawerWrapper
			width={480}
			closable={false}
			placement="right"
			open={isNotificationsOpen}
			onClose={onToggleNotifications}
		>
			{children}
		</S.DrawerWrapper>
	);
}
