import styled, { keyframes } from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, DatePicker, Drawer, Modal, Select, Typography } from 'antd';

export const DrawerWrapper = styled(Drawer)`
	.ant-drawer-mask {
		background: unset;
	}

	.ant-drawer-content-wrapper {
		border-top-left-radius: 12px;
		max-height: 85%;
		position: fixed;
		overflow: auto;
		top: unset;
		bottom: 0;
		right: 0;
	}
`;

export const CheckboxGroupWrapper = styled(Checkbox.Group)`
	display: flex;
	flex-direction: column;

	.ant-checkbox-wrapper {
		margin-bottom: 1rem;
		font-size: 1rem;
	}
`;

const fadeIn = keyframes`
	from {
    	opacity: 0;
    	transform: translateY(20px);
  	}
  	to {
    	opacity: 1;
    	transform: translateY(0);
  	}
`;

export const CardWrapper = styled(Card)`
	left: 50%;
	bottom: 16px;
	padding: 3px;
	z-index: 1000;
	position: fixed;
	border-radius: 6px;
	transform: translateX(-50%);
	box-shadow: 2px 2px 10px 2px #00000040;

	.ant-card-body {
		margin: 0;
		padding: 0;
		display: flex;
		animation: ${fadeIn} 0.3s ease-out;
	}
`;

export const StyledButton = styled(Button)`
	border: none;
	display: flex;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	padding: 6.4px 10px;
`;

export const ModalWrapper = styled(Modal)`
	.ant-modal-content {
		border-radius: 30px;
		.title-modal {
			font-size: 35px;
			text-align: center;
		}
	}
`;

export const TitleSpan = styled.span`
	cursor: pointer;
`;

export const DueDateSpan = styled.span<{ isOverdue: boolean }>`
	color: ${({ isOverdue }) => (isOverdue ? '#E74150' : 'unset')};
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
	font-size: 68px;
	color: #e74150;
`;

export const StyledSelect = styled(Select)`
	.ant-select-selection-placeholder {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: left;
	}
`;

export const DatePickerWrapper = styled(DatePicker)`
	width: 100%;
	.ant-picker-input > input:placeholder-shown {
		font-size: 16px;
	}
`;

export const StyledIconEdit = styled.span<{ color?: string }>`
	color: ${({ color }) => color || '#2F54EB'};
	font-size: 22px;
`;

export const DeleteTypography = styled(Typography)`
	font-size: 24px;
	font-weight: 400;
	line-height: 39.9px;
	text-align: center;
	padding-top: 20px;
`;

export const Container = styled.div`
	height: 100%;
	width: 100%;
	padding: 0;

	.ant-table {
		text-align: left;
		font-weight: 400;
		line-height: 21.28px;
	}

	.ant-table th {
		font-weight: 600;
	}

	.ant-table-content {
		overflow: unset !important;
	}

	.ant-table-thead > tr > th::before {
		content: none !important;
	}

	.ant-table.ant-table-small .ant-table-tbody > tr > td {
		padding: 2px;
	}

	.ant-table-thead > tr > th {
		border-bottom: 0;
		background: white;
	}

	.ant-table table {
		border-spacing: 0 4px;
		border-collapse: separate;
	}

	.ant-table-tbody > tr.ant-table-row-selected > td {
		border-color: #2f54eb;
		background-color: #eaeefd;
	}

	.custom-selected-row td {
		border: 3px solid;
		border-left: none;
		border-right: none;
	}

	.custom-selected-row td:first-child {
		border-left: 3px solid;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.custom-selected-row td:last-child {
		border-right: 3px solid;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`;

export const TableWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-height: calc(100vh - 370px);
	overflow-y: scroll !important;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 3px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.ant-table .ant-table-container::before,
	.ant-table .ant-table-container::after {
		content: none !important;
	}

	.ant-table-sticky-holder {
		overflow: unset !important;
	}

	.ant-table-body {
		overflow: visible !important;
	}

	.ant-table-sticky-scroll {
		display: none !important;
	}

	table {
		background: white;
	}
`;
