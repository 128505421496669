import React from 'react';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { CSS } from '@dnd-kit/utilities';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Checkbox } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import * as S from './styles';
import { ProgressBar } from './ProgressBar';
import { Title } from '@/components/Typography';
import { isColorDark, priorityLabel } from './utils';
import { Flag } from '@/assets/icons/action-plans/flag';
import { ActionPlanInfo } from '@/views/ActionPlans/types';
import { getResultRPN, riskColors } from '@/utils/riskRange';
import { Checked } from '@/assets/icons/action-plans/checked';
import { Calendar } from '@/assets/icons/action-plans/calendar';

interface CardProps {
	id: string;
	item: {
		id: string;
		title: string;
		status: string;
		score?: number;
		due_date: Date;
		lexo_rank: string;
		company_id: string;
		priority: 1 | 2 | 3;
		total_tasks: number;
		completed_tasks: number;
	};
	containerId?: string;
	isSelected?: boolean;
	onClick({ id, company_id }: ActionPlanInfo): void;
	onSelect?: (id: string, checked: boolean, company_id: string) => void;
}

export const Card = React.memo(({ id, item, containerId, isSelected, onSelect, onClick }: CardProps) => {
	const { attributes, listeners, transform, transition, isDragging, setNodeRef } = useSortable({
		id,
		data: {
			type: 'card',
			item,
			containerId
		},
		transition: {
			duration: 150,
			easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
		}
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.5 : 1,
		willChange: 'transform',
		touchAction: 'none'
	};

	function handleOnFormatDate(date: Date): string {
		return moment(date).format('DD/MM/YYYY');
	}

	function handleOnClick(): void {
		const params = {
			id,
			company_id: item.company_id
		};

		onClick(params);
	}

	const isExpired = moment(item?.due_date).isBefore(moment(), 'day');

	const handleCheckboxChange = (e: CheckboxChangeEvent) => {
		onSelect?.(id, e.target.checked, item.company_id);
	};

	function validateScore(score: number): boolean {
		return score !== undefined && score !== null && score > 0;
	}

	return (
		<S.Card
			{...listeners}
			{...attributes}
			ref={setNodeRef}
			isDragging={isDragging}
			isSelected={isSelected}
			style={style}
		>
			<Row>
				<Col span={23}>
					<S.ClickableArea onClick={handleOnClick}>
						<S.TitleWrapper>
							<Title level={5}>{item?.title}</Title>
						</S.TitleWrapper>
					</S.ClickableArea>
				</Col>
				<Col span={1} onClick={(e) => e.stopPropagation()}>
					<Checkbox checked={isSelected} onChange={handleCheckboxChange} />
				</Col>
			</Row>
			<S.ClickableArea onClick={handleOnClick}>
				<Row style={{ marginLeft: 10 }}>
					<Col span={24}>
						<S.Paragraph isExpired={isExpired}>
							<Icon
								component={(props: any) => <Calendar {...props} color={isExpired ? 'red' : 'black'} />}
							/>
							{handleOnFormatDate(item?.due_date)}
						</S.Paragraph>
					</Col>
					<Col span={24}>
						<S.Paragraph>
							<Icon component={Checked as React.FC} /> {item?.completed_tasks}/{item?.total_tasks}
						</S.Paragraph>
					</Col>
					<Col span={24}>
						<S.Paragraph>
							<Icon component={Flag as React.FC} /> {I18n.get(priorityLabel[item?.priority] || 'N/A')}
						</S.Paragraph>
					</Col>
					<Col xl={13} xxl={16}>
						<ProgressBar completed={item?.completed_tasks} total={item?.total_tasks} />
					</Col>
					<Col xl={11} xxl={8} style={{ textAlign: 'end' }}>
						{validateScore(item?.score || 0) && (
							<S.Score
								isDark={isColorDark(riskColors[getResultRPN(item?.score || 0)])}
								color={riskColors[getResultRPN(item?.score || 0)]}
							>
								{I18n.get('RPN')}: {item?.score}
							</S.Score>
						)}
					</Col>
				</Row>
			</S.ClickableArea>
		</S.Card>
	);
});
