import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Col, Form, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useCreateActionPlanContext } from '../context';
import { DueDateDropdown, ResponsibleDropdown, TaskName } from '../../components/Fields';

const { useWatch, useFormInstance } = Form;

export function Creation() {
	const fieldName = ['task'];

	const { users, tasks, onUpdateTasks } = useCreateActionPlanContext();

	const form = useFormInstance();

	const taskName = useWatch([...fieldName, 'task_name']);
	const actionPlanDueDate = useWatch(['create_action_plan', 'due_date']);

	function handleAdd(): void {
		const values = form.getFieldValue(fieldName);

		const item = {
			id: uuidv4(),
			is_completed: false,
			title: values.task_name,
			due_date: values.due_date,
			responsible_user_id: values.responsible_id
		};

		const tasksCopy = [...tasks];
		tasksCopy.push(item);
		onUpdateTasks(tasksCopy);

		form.setFieldValue([...fieldName, 'due_date'], undefined);
		form.setFieldValue([...fieldName, 'task_name'], undefined);
		form.setFieldValue([...fieldName, 'responsible_id'], undefined);
	}

	return (
		<Row justify="space-between" align="middle">
			<Col span={18}>
				<TaskName formName={fieldName} />
			</Col>
			<Col>
				<DueDateDropdown formName={fieldName} limitDate={actionPlanDueDate} />
			</Col>
			<Col>
				<ResponsibleDropdown formName={fieldName} options={users} />
			</Col>
			<Col>
				<Button
					type="primary"
					shape="circle"
					onClick={handleAdd}
					icon={<PlusOutlined />}
					disabled={!taskName}
				/>
			</Col>
		</Row>
	);
}
