import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useEditActionPlanContext } from '../context';
import { ResponsibleDropdown, TaskName } from '../../../Fields';
import { DueDateDropdown } from '../../../Fields/DueDateDropdown';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useCreateActionPlanTask } from '@/hooks/v2/useCreateActionPlanTask';

const { useWatch, useFormInstance } = Form;

export function Creation() {
	const form = useFormInstance();
	const { users, actionPlan } = useEditActionPlanContext();
	const { organization, company } = useApplicationContext();

	const fieldName = ['task'];

	const { mutateAsync: createTask } = useCreateActionPlanTask();

	const taskName = useWatch([...fieldName, 'task_name']);

	async function handleAdd(): Promise<void> {
		const values = form.getFieldValue(fieldName);

		const payload = {
			organization_id: organization?.id,
			company_id: company?.id,
			action_plan_id: actionPlan.id,
			due_date: values.due_date,
			responsible_user_id: values.responsible_id,
			title: values.task_name,
			is_completed: false
		};

		await createTask(payload);

		cleanAllFields();
	}

	function cleanAllFields(): void {
		form.setFieldValue([...fieldName, 'due_date'], undefined);
		form.setFieldValue([...fieldName, 'task_name'], undefined);
		form.setFieldValue([...fieldName, 'responsible_id'], undefined);
	}

	return (
		<Row justify="space-between" align="middle">
			<Col span={19}>
				<TaskName formName={fieldName} />
			</Col>
			<Col>
				<DueDateDropdown formName={fieldName} limitDate={actionPlan.due_date} />
			</Col>
			<Col>
				<ResponsibleDropdown formName={fieldName} options={users} />
			</Col>
			<Col>
				<Button
					type="primary"
					shape="circle"
					onClick={handleAdd}
					icon={<PlusOutlined />}
					disabled={!taskName}
				/>
			</Col>
		</Row>
	);
}
