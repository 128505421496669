import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Title } from '@/components/Typography';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import * as S from './styles';

const { useForm } = Form;

interface FormData {
	columns: string[];
}

type ColumnItem = {
	label: string;
	value: string;
};

interface DrawerColumnsSelectProps {
	open: boolean;
	options: ColumnItem[];
	selected: string[];
	onSubmit: (values: string[]) => void;
	onClose: () => void;
}

export function DrawerColumnsSelect({
	open = false,
	options = [],
	selected = [],
	onSubmit,
	onClose
}: Readonly<DrawerColumnsSelectProps>) {
	const [form] = useForm<FormData>();

	async function handleOnSubmit(): Promise<void> {
		const data = await form.validateFields();
		onSubmit(data.columns);
		onClose();
	}

	const initialValues = { columns: selected };

	return (
		<S.DrawerWrapper open={open} placement="right" closable={false} onClose={onClose}>
			<Form
				form={form}
				layout="vertical"
				onFinish={handleOnSubmit}
				initialValues={initialValues}
				style={{ width: '100%' }}
			>
				<Row justify="space-between" gutter={[16, 16]} style={{ paddingLeft: '20px' }}>
					<Col>
						<Title level={4} style={{ margin: 0 }}>
							{I18n.get('Manage Columns')}
						</Title>
					</Col>
					<Col>
						<Button type="text" icon={<CloseOutlined />} onClick={onClose} />
					</Col>
					<Col span={24}>
						<Form.Item name="columns">
							<S.CheckboxGroupWrapper options={options} />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Row justify="center" gutter={[16, 0]}>
							<Col>
								<CustomButton key="cancel" onClick={onClose}>
									{I18n.get('Cancel')}
								</CustomButton>
							</Col>
							<Col>
								<CustomButton type="primary" htmlType="submit">
									{I18n.get('Save')}
								</CustomButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</S.DrawerWrapper>
	);
}
