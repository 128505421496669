import React from 'react';
import { Progress } from 'antd';

type ProgressBarProps = {
	total?: number;
	completed?: number;
} & typeof defaultProps;

const defaultProps = {
	total: 0,
	completed: 0
};

export function ProgressBar({ completed, total }: Readonly<ProgressBarProps>) {
	const percent = Math.trunc((completed / total) * 100);
	return <Progress percent={percent} strokeColor="#2F54EB" trailColor="#E6E6E6" />;
}
