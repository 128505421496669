import React, { useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Grid } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';

import {
	Line,
	Sector,
	Status,
	Origin,
	Company,
	DueDate,
	Priority,
	Responsible,
	Workstation,
	Organization,
	ActionPlanName,
	InvestmentButton
} from '../../Fields';
import { Footer } from './Footer';
import { Text, Title } from '@/components/Typography';
import { useGetOrigins } from '@/hooks/v2/useGetOrigins';
import { useGetLineOptions } from '@/hooks/v2/useGetLineOptions';
import { useGetSectorOptions } from '@/hooks/v2/useGetSectorOptions';
import { useGetCompanyOptions } from '@/hooks/v2/useGetCompanyOptions';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useWorkstationOptions } from '@/hooks/v2/useGetWorkstationOptions';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsableOptions';
import * as S from './styles';

const { useBreakpoint } = Grid;
const { useWatch, useFormInstance } = Form;

const ORIGINS_LIMIT = 30;
const ORIGINS_OFFSET = 1;

interface ModalCreateProps {
	open: boolean;
	onClose(): void;
}

export function ModalCreate({ open = false, onClose }: Readonly<ModalCreateProps>) {
	const form = useFormInstance();
	const { xxl } = useBreakpoint();
	const { organization, company } = useApplicationContext();

	const [timer, setTimer] = useState<NodeJS.Timeout>();
	const [originName, setOriginName] = useState<string>();

	const fieldName = ['create_action_plan'];

	const company_id = useWatch([...fieldName, 'company_id']);
	const sector_id = useWatch([...fieldName, 'sector_id']);
	const line_id = useWatch([...fieldName, 'line_id']);
	const workstation_id = useWatch([...fieldName, 'workstation_id']);

	const {
		isFetching: isGettingCompanies,
		data: companies,
		isError: hasErrorCompanies
	} = useGetCompanyOptions({
		organization_id: organization?.id,
		company_id: company?.id
	});

	const {
		isFetching: isGettingSectors,
		data: sectors,
		isError: hasErrorSectors
	} = useGetSectorOptions({
		organization_id: organization?.id,
		company_id
	});

	const {
		isFetching: isGettingLines,
		data: lines,
		isError: hasErrorLines
	} = useGetLineOptions({
		organization_id: organization?.id,
		company_id,
		sector_id
	});

	const {
		isFetching: isGettingWorkstations,
		data: workstations,
		isError: hasErrorWorkstations
	} = useWorkstationOptions({
		organization_id: organization?.id,
		company_id,
		line_id
	});

	const {
		isFetching: isGettingUsers,
		data: users,
		isError: hasErrorUsers
	} = useGetResponsableOptions({
		organization_id: organization?.id,
		company_id
	});

	const { isFetching: isGettingOrigins, data: origins } = useGetOrigins({
		organization_id: organization?.id,
		company_id,
		workstation_id,
		report_name: originName,
		limit: ORIGINS_LIMIT,
		offset: ORIGINS_OFFSET
	});

	function handleOnClose(): void {
		form.resetFields(fieldName);
		onClose();
	}

	function handleDebounceSetValues(value: string): void {
		clearTimeout(timer);

		const debounce = setTimeout(() => {
			setOriginName(value);
		}, 1200);

		setTimer(debounce);
	}

	function handleOnSearch(value: string): void {
		handleDebounceSetValues(value);
	}

	function formatSelectOptions(): DefaultOptionType[] | undefined {
		if (origins?.rows.length === 0) {
			return undefined;
		}

		return origins?.rows.map((origin) => {
			const originValues = {
				origin_name: origin.origin_name,
				table_name: origin.table_name,
				column_id: origin.column_id,
				file_id: origin.file_id
			};

			const originStringified = JSON.stringify(originValues);

			return {
				value: originStringified,
				label: (
					<Row>
						<Col span={14}>
							<Text ellipsis>{origin.report_name}</Text>
						</Col>
						<S.CustomColumn span={5}>{moment(origin.created_at).format('L')}</S.CustomColumn>
						<S.CustomColumn span={5}>{I18n.get(origin.origin_name)}</S.CustomColumn>
					</Row>
				)
			};
		});
	}

	return (
		<S.ModalWrapper open={open} centered={xxl} width={700} onCancel={handleOnClose} footer={null}>
			<Row gutter={[45, 25]}>
				<Col span={24}>
					<Title level={4} style={{ margin: 0 }}>
						Create an action plan
					</Title>
				</Col>
				<Col span={24}>
					<ActionPlanName formName={fieldName} isRequired />
				</Col>
				<Col span={12}>
					<Organization
						isDisabled
						formName={fieldName}
						options={[
							{
								label: organization?.name,
								value: organization?.id
							}
						]}
					/>
				</Col>
				<Col span={12}>
					<Company
						isRequired
						options={companies}
						formName={fieldName}
						isLoading={isGettingCompanies}
						isDisabled={hasErrorCompanies}
					/>
				</Col>
				<Col span={12}>
					<Sector
						isRequired
						options={sectors}
						formName={fieldName}
						isLoading={isGettingSectors}
						isDisabled={hasErrorSectors}
					/>
				</Col>
				<Col span={12}>
					<Line
						isRequired
						options={lines}
						formName={fieldName}
						isLoading={isGettingLines}
						isDisabled={hasErrorLines}
					/>
				</Col>
				<Col span={12}>
					<Workstation
						isRequired
						formName={fieldName}
						options={workstations}
						isLoading={isGettingWorkstations}
						isDisabled={hasErrorWorkstations}
					/>
				</Col>
				<Col span={12}>
					<Responsible
						isRequired
						options={users}
						formName={fieldName}
						isLoading={isGettingUsers}
						isDisabled={hasErrorUsers || !company_id}
					/>
				</Col>
				<Col span={12}>
					<DueDate formName={fieldName} isRequired />
				</Col>
				<Col span={12}>
					<Priority formName={fieldName} tooltip />
				</Col>
				<Col span={12}>
					<Status formName={fieldName} />
				</Col>
				<Col span={12}>
					<InvestmentButton formName={fieldName} />
				</Col>
				<Col span={24}>
					<Origin
						formName={fieldName}
						onSearch={handleOnSearch}
						isLoading={isGettingOrigins}
						isDisabled={!workstation_id}
						options={formatSelectOptions()}
					/>
				</Col>
				<Col span={24}>
					<Footer formName={fieldName} onClose={handleOnClose} />
				</Col>
			</Row>
		</S.ModalWrapper>
	);
}
