export const isColorDark = (color: string) => {
	const rgb = parseInt(color.slice(1), 16);
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = (rgb >> 0) & 0xff;
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return brightness < 128;
};

export const priorityLabel = {
	1: 'Low',
	2: 'Medium',
	3: 'High'
};
