import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import { Paragraph } from '@/components/Typography';
import { Description } from './styles';
import { Tasks } from '@/hooks';

interface TaskProps {
	task: Tasks;
}

export function Task({ task }: TaskProps) {
	return (
		<Row align="middle" justify="space-between">
			<Description span={9} style={{ borderRight: '1px solid #d9d9d9', textAlign: 'center' }}>
				<Paragraph>{task.title}</Paragraph>
			</Description>
			<Col span={8} style={{ borderRight: '1px solid #d9d9d9', textAlign: 'center' }}>
				{task.responsible_user?.name ? task.responsible_user?.name : '-'}
			</Col>
			<Col span={7}>{task.due_date ? moment(task.due_date).format('L') : '-'}</Col>
		</Row>
	);
}
