import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';
import { UnorderedListOutlined } from '@ant-design/icons';

import { Panels } from '../constants';
import { ButtonExportCSV } from './ExportCSV';
import { Title } from '@/components/Typography';
import { useActionPlanContext } from '../context';
import { BarsIcon } from '@/assets/icons/bars-icon';
import { useQueryParams } from '@/hooks/v2/useQueryParams';
import * as S from './styles';

export function Header() {
	const history = useHistory();
	const location = useLocation();
	const query = useQueryParams();
	const { currentPanel } = useActionPlanContext();

	function handleOnChangePanel(value: string): void {
		query.set('panel', value);
		history.replace({ pathname: location.pathname, search: query.toString() });
	}

	return (
		<Row justify="space-between" align="middle">
			<Col>
				<Title level={4} style={{ margin: 0 }}>
					Action plans
				</Title>
			</Col>
			<Col>
				<Row align="middle" gutter={[20, 0]}>
					<Col>
						<S.PanelButton
							icon={<BarsIcon />}
							$selected={currentPanel === Panels.BOARD}
							onClick={() => handleOnChangePanel(Panels.BOARD)}
						>
							{I18n.get('Board')}
						</S.PanelButton>
					</Col>
					<Col>
						<S.PanelButton
							icon={<UnorderedListOutlined />}
							$selected={currentPanel === Panels.TABLE}
							onClick={() => handleOnChangePanel(Panels.TABLE)}
						>
							{I18n.get('Table')}
						</S.PanelButton>
					</Col>
					<Col>{currentPanel === Panels.TABLE && <ButtonExportCSV />}</Col>
				</Row>
			</Col>
		</Row>
	);
}
