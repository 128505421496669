import React from 'react';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Tasks } from './Tasks';
import { Footer } from './Footer';
import { ActionPlanHierarchy } from '../types';
import { Title } from '@/components/Typography';
import { RiskEvaluation } from '../components/Risk';
import { CreateActionPlanProvider } from './context';
import { DueDate } from '../components/Fields/DueDate';
import { Priority } from '../components/Fields/Priority';
import { Description } from '../components/Fields/Description';
import { Responsible } from '../components/Fields/Responsible';
import { ActionPlanName } from '../components/Fields/ActionPlanName';
import { InvestmentRange } from '../components/Fields/InvestmentRange';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetResponsableOptions } from '@/hooks/v2/useGetResponsableOptions';

const { useFormInstance } = Form;

interface CreateActionPlanProps {
	score: number;
	onClose(): void;
	hierarchy: ActionPlanHierarchy;
}

export function CreateActionPlan({ score, onClose, hierarchy }: Readonly<CreateActionPlanProps>) {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();

	const { custom_report_sub_step_key, custom_report_step_key, sera_summary_review } = hierarchy;
	const field_id = custom_report_sub_step_key || custom_report_step_key || sera_summary_review;
	const fieldName = ['create_action_plan', field_id ?? ''];

	const { data: users } = useGetResponsableOptions({
		organization_id: organization.id,
		company_id: company.id
	});

	function handleOnClose() {
		form.setFieldValue(fieldName, undefined);
		onClose();
	}

	return (
		<CreateActionPlanProvider hierarchy={hierarchy} users={users}>
			<Row gutter={[30, 30]}>
				<Col span={24}>
					<Title level={3} style={{ margin: 0 }}>
						{I18n.get('Create an action plan')}
					</Title>
				</Col>
				<Col span={18}>
					<ActionPlanName formName={fieldName} isRequired />
				</Col>
				<Col span={6}>
					<RiskEvaluation score={score} />
				</Col>
				<Col span={12}>
					<Responsible formName={fieldName} options={users} isRequired />
				</Col>
				<Col span={12}>
					<DueDate formName={fieldName} isRequired />
				</Col>
				<Col span={12}>
					<Priority formName={fieldName} />
				</Col>
				<Col span={12}>
					<InvestmentRange formName={fieldName} />
				</Col>
				<Col span={24}>
					<Description formName={fieldName} />
				</Col>
				<Col span={24}>
					<Tasks />
				</Col>
				<Col span={24}>
					<Footer formName={fieldName} onClose={handleOnClose} />
				</Col>
			</Row>
		</CreateActionPlanProvider>
	);
}
