import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Col, Button, Progress, Table, TableColumnsType, TableProps, Tooltip, Skeleton } from 'antd';

import * as S from './styles';
import { Score } from './Score';
import { Avatar } from './Avatar';
import { Status } from './Status';
import { Actions } from '../Actions';
import type { DataType } from './types';
import type { ActionPlanInfo } from '../../types';
import type { ColumnType } from 'antd/lib/table';
import { useActionPlanContext } from '../../context';
import { DollarSignIcon } from '@/assets/icons/dollar-sign';
import { DrawerColumnsSelect } from './DrawerColumnsSelect';
import { ActionPlanList } from '@/core/domain/action-plan-list';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlanList } from '@/views/Dashboard/hooks/useActionPlanList';
import { useCreatePreferenceColumnsActionPlan } from '@/hooks/v2/useCreatePreferenceColumnsActionPlan';
import { useGetUserPreferenceColumnsActionPlan } from '@/hooks/v2/useGetUserPreferenceColumnsActionPlan';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const defaultPreferences = ['score', 'responsible_user_id', 'due_date', 'priority', 'tasks'];
const unsedDataIndexes: string[] = ['plus', 'title', 'status'];

export function List() {
	const query = useQuery();
	const ref = useRef<HTMLDivElement>(null);
	const { filter, handleSetRows, handleSetColumns, onToggleEditModal, selectActionPlan } = useActionPlanContext();
	const { organization, company } = useApplicationContext();
	const company_id = query.get('company');
	const sort = query.get('sort');

	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>();
	const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
	const hasSelectedRows = useMemo(() => !!selectedRows && selectedRows.length > 0, [selectedRows]);

	function canChangeCardResponsible(): boolean {
		if (!selectedCompanyIds.length) {
			return false;
		}
		const firstCompanyId = selectedCompanyIds[0];
		return selectedCompanyIds.every((companyId) => companyId === firstCompanyId);
	}

	const parameters = useMemo(
		() => ({
			...filter,
			limit: 20,
			offset: 0,
			sort: sort ?? undefined,
			origin_name: filter.origin,
			title: filter.action_plan_name,
			organization_id: organization?.id!,
			due_date_end: filter.due_date?.[1].toDate(),
			end_date: filter.creation_date?.[1].toDate(),
			due_date_start: filter.due_date?.[0].toDate(),
			start_date: filter.creation_date?.[0].toDate(),
			company_id: company_id ?? company.id ?? undefined
		}),
		[organization?.id, company_id, sort, filter, company.id]
	);

	const { isLoading, data, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
		useGetActionPlanList(parameters);

	const { data: dataUserPreferenceColumns, isError: hasErrorUserPreferenceColumns } =
		useGetUserPreferenceColumnsActionPlan({
			organization_id: organization?.id!,
			company_id: company?.id
		});

	const { mutateAsync: createUserPreferenceColumns } = useCreatePreferenceColumnsActionPlan();

	const rows = useMemo<ActionPlanList[]>(() => data?.pages.flatMap((page) => page.data) ?? [], [data?.pages]);

	const loadMore = useCallback(() => {
		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, isFetchingNextPage, fetchNextPage]);

	function handleOnClickTitle(data: ActionPlanInfo): void {
		onToggleEditModal();
		selectActionPlan(data);
	}

	const columns: TableColumnsType<ActionPlanList> = [
		{
			title: '',
			width: 300,
			key: 'title',
			fixed: 'left',
			dataIndex: 'title',
			render: (title, record) => {
				const params = {
					id: record.id,
					company_id: record.company_id
				};

				return <S.TitleSpan onClick={() => handleOnClickTitle(params)}>{I18n.get(title)}</S.TitleSpan>;
			}
		},
		{
			width: 150,
			key: 'status',
			fixed: 'left',
			title: 'Status',
			dataIndex: 'status',
			render: (status) => (
				<Status status={status} bordered={hasSelectedRows}>
					{I18n.get(status)}
				</Status>
			)
		},
		{
			width: 150,
			key: 'score',
			dataIndex: 'score',
			title: I18n.get('RPN'),
			render: (score) => <Score value={score} />
		},
		{
			width: 130,
			dataIndex: 'responsible',
			key: 'responsible_user_id',
			title: I18n.get('Responsible'),
			render: (responsible) => {
				return (
					<Tooltip title={responsible.name}>
						<Avatar responsibleName={responsible.name} url={responsible.url_logo} />
					</Tooltip>
				);
			}
		},
		{
			width: 135,
			key: 'due_date',
			dataIndex: 'dueDate',
			title: I18n.get('Due Date'),
			render: (dueDate) => {
				const isOverdue = moment() > moment(dueDate);
				return (
					<S.DueDateSpan isOverdue={isOverdue}>
						{moment(dueDate).isValid() ? moment(dueDate).format('L') : '-'}
					</S.DueDateSpan>
				);
			}
		},
		{
			width: 135,
			key: 'completed_at',
			dataIndex: 'completedAt',
			title: I18n.get('Date of Completion'),
			render: (value) => handleValidationDate(value)
		},
		{
			width: 110,
			key: 'priority',
			dataIndex: 'priority',
			title: I18n.get('Priority'),
			render: (value) => I18n.get(value)
		},
		{
			width: 135,
			key: 'investment',
			dataIndex: 'investment',
			title: I18n.get('Investment'),
			render: (value) => {
				return (
					<Row>
						{Array.from({ length: value }).map((_) => (
							<Col>
								<DollarSignIcon style={{ marginRight: '0.5rem', width: '1.2rem', height: '1.2rem' }} />
							</Col>
						))}
					</Row>
				);
			}
		},
		{ width: 140, title: I18n.get('Attachments'), dataIndex: 'attachments', key: 'attachments' },
		{
			width: 110,
			key: 'tasks',
			dataIndex: 'tasks',
			title: I18n.get('Tasks'),
			render: (_, record) => {
				const { completedTasks, totalTasks } = record;
				return `${completedTasks} / ${totalTasks}`;
			}
		},
		{
			width: 200,
			key: 'progress',
			dataIndex: 'progress',
			title: I18n.get('Progress'),
			render: (value) => (
				<Progress
					style={{
						paddingRight: '60px'
					}}
					percent={value}
					success={{ percent: 0 }}
					strokeColor="#4f00eb"
					trailColor="#00000033"
					format={(percent) => (percent === 100 ? '100%' : `${percent}%`)}
				/>
			)
		},
		{ width: 130, key: 'evidences', dataIndex: 'evidences', title: I18n.get('Evidences') },
		{
			width: 130,
			key: 'author',
			dataIndex: 'author',
			title: I18n.get('Author'),
			render: (author) => (
				<Tooltip title={author.name}>
					<Avatar responsibleName={author.name} url={author.url_logo} />
				</Tooltip>
			)
		},
		{
			width: 130,
			key: 'origin',
			dataIndex: 'origin',
			title: I18n.get('Origin'),
			render: (value) => I18n.get(value)
		},
		{
			width: 135,
			key: 'created_at',
			dataIndex: 'createdAt',
			title: I18n.get('Creation date'),
			render: (value) => handleValidationDate(value)
		},
		{
			key: 'plus',
			width: '50px',
			dataIndex: 'plus',
			title: (
				<Button
					size="small"
					type="primary"
					shape="circle"
					icon={<PlusOutlined />}
					onClick={handleOnOpenDrawer}
				/>
			)
		}
	];

	const [activeColumns, setActiveColumns] = useState<string[]>([]);

	useEffect(() => {
		if (dataUserPreferenceColumns) {
			const userPreferences = dataUserPreferenceColumns.length ? dataUserPreferenceColumns : defaultPreferences;
			const columnsKeys: string[] = columns.map((value) => value.key as string);
			const columnsSelected = columnsKeys.filter((value) => userPreferences?.includes(value));
			setActiveColumns(columnsSelected);
		}
	}, [dataUserPreferenceColumns]);

	function handleOnOpenDrawer(): void {
		setIsOpenDrawer((prevState) => !prevState);
	}

	function handleOnSubmitDrawer(values: string[]): void {
		setActiveColumns(values);

		const parameters = {
			organization_id: organization?.id!,
			company_id: company?.id,
			columns: values
		};

		createUserPreferenceColumns(parameters);
	}

	function handleValidationDate(value: string): string {
		return moment(value).isValid() ? moment(value).format('L') : '-';
	}

	const isValidDataIndex = (item: TableColumnsType<DataType>[number]) => {
		return 'dataIndex' in item && !unsedDataIndexes.includes(item.dataIndex as string);
	};

	const isActiveColumn = (item: ColumnType<DataType>) =>
		activeColumns.includes(item.key as string) || unsedDataIndexes.includes(item.key as string);

	const options = columns.filter(isValidDataIndex).map(({ key, title }) => ({
		label: title as string,
		value: key as string
	}));

	const filteredColumns: TableColumnsType<DataType> = columns.filter(isActiveColumn);

	function handleRowSelectClassName(row: DataType): string {
		return selectedRows?.includes(row.id) ? 'custom-selected-row' : '';
	}

	const rowSelection: TableProps<DataType>['rowSelection'] = {
		columnTitle: null,
		onChange: (rows: React.Key[], selectedRows: DataType[]) => {
			setSelectedRows(rows);
			setSelectedCompanyIds(selectedRows.map((row) => row.company_id));
		}
	};

	useEffect(() => {
		rows && handleSetRows(rows);
	}, [rows, handleSetRows]);

	useEffect(() => {
		handleSetColumns(filteredColumns);
	}, [activeColumns]);

	if (isError || hasErrorUserPreferenceColumns) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<>
			<DrawerColumnsSelect
				options={options}
				open={isOpenDrawer}
				selected={activeColumns}
				onClose={handleOnOpenDrawer}
				onSubmit={handleOnSubmitDrawer}
			/>
			<S.Container>
				<S.TableWrapper id="scrollableDiv" style={{ overflowX: 'auto' }}>
					<InfiniteScroll
						next={loadMore}
						hasMore={!!hasNextPage}
						dataLength={rows.length}
						style={{ overflow: 'visible' }}
						scrollableTarget="scrollableDiv"
						loader={isFetchingNextPage && <Skeleton.Input active={true} block={true} />}
					>
						<Table<DataType>
							ref={ref}
							rowKey="id"
							size="small"
							dataSource={rows}
							pagination={false}
							loading={isLoading}
							columns={filteredColumns}
							rowSelection={rowSelection}
							rowClassName={handleRowSelectClassName}
							sticky={{ offsetHeader: 0, offsetScroll: -1 }}
							scroll={{ x: filteredColumns.length > 8 ? '100vw' : undefined }}
						/>
					</InfiniteScroll>
				</S.TableWrapper>
			</S.Container>
			{hasSelectedRows && (
				<Actions
					id={selectedRows || []}
					organizationId={organization.id}
					companyId={company_id ?? company.id}
					canChangeResponsible={canChangeCardResponsible()}
				/>
			)}
		</>
	);
}
