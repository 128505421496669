import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useWatch } from 'antd/lib/form/Form';
import { Col, Form, Row, message } from 'antd';

import { useEditActionPlanContext } from './context';
import { useUpdateActionPlan } from '@/hooks/v2/useUpdateActionPlan';
import * as S from '../ModalCreate/styles';

const { useFormInstance } = Form;

interface FooterProps {
	formName: string[];
	onClose(): void;
}

export function Footer({ formName, onClose }: Readonly<FooterProps>) {
	const form = useFormInstance();
	const { actionPlan } = useEditActionPlanContext();
	const { mutateAsync: updateActionPlan } = useUpdateActionPlan();

	const fieldName = formName[0];
	const taskName = useWatch(['task', 'task_name']);

	async function handleOnSubmit(): Promise<void> {
		try {
			if (taskName) {
				throw Error(I18n.get('There are tasks that have not been saved.'));
			}

			const formValues = await form.validateFields(formName);
			const values = formValues[fieldName][actionPlan.id];

			const payload = {
				id: actionPlan.id,
				status: values.status,
				due_date: values.due_date,
				priority: values.priority,
				title: values.action_plan_name,
				description: values.description,
				company_id: actionPlan.company.id,
				responsible_user_id: values.responsible_id,
				investment_range: values.investment_range,
				investment_value: values.investment_value,
				organization_id: actionPlan.organization.id
			};

			await updateActionPlan(payload);
			onClose();
		} catch (error: any) {
			const errorMessage = error?.response?.data.message || error.message;
			message.error(I18n.get(errorMessage));
		}
	}

	return (
		<Row justify="center" gutter={[12, 0]}>
			<Col>
				<S.SecondaryButton onClick={onClose}>{I18n.get('Cancel')}</S.SecondaryButton>
			</Col>
			<Col>
				<S.PrimaryButton type="primary" onClick={handleOnSubmit}>
					{I18n.get('Save')}
				</S.PrimaryButton>
			</Col>
		</Row>
	);
}
