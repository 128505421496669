import React from 'react';
import { Row, Col } from 'antd';
import { useEditActionPlanContext } from '../context';
import { DueDate, InvestmentButton, InvestmentValue, Priority, Responsible } from '../../../Fields';

interface DetailsProps {
	formName: string[];
}

export function Details({ formName }: Readonly<DetailsProps>) {
	const { users } = useEditActionPlanContext();

	return (
		<Row gutter={[45, 25]}>
			<Col span={12}>
				<Responsible formName={formName} options={users} isRequired />
			</Col>
			<Col span={12}>
				<DueDate formName={formName} isRequired />
			</Col>
			<Col span={12}>
				<Priority formName={formName} tooltip />
			</Col>
			<Col span={12}>
				<Row gutter={[0, 25]}>
					<Col span={24}>
						<InvestmentButton formName={formName} tooltip />
					</Col>
					<Col span={12}>
						<InvestmentValue formName={formName} />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
