import React, { useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';

import { Name } from './Name';
import { Tasks } from './Tasks';
import { Footer } from './Footer';
import { Details } from './Details';
import { History } from './History';
import { Comments } from './Comments';
import { Description } from '../../Fields';
import { DownloadPdf } from './DownloadPdf';
import { Attachments } from './Attachments';
import { Informations } from './Informations';
import { Refetch } from '@/components/ui/Refetch';
import { EditActionPlanProvider } from './context';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useGetActionPlan } from '@/hooks/v2/useGetActionPlan';
import type { ActionPlanInfo } from '@/views/ActionPlans/types';
import { useApplicationContext } from '@/context/v1/Application/context';

const { useFormInstance } = Form;

interface ContentProps {
	data: ActionPlanInfo;
	onClose(): void;
}

export function Content({ data, onClose }: Readonly<ContentProps>) {
	const form = useFormInstance();
	const { organization } = useApplicationContext();

	const fieldName = ['edit_action_plan', data.id];

	const payload = {
		organization_id: organization?.id,
		company_id: data.company_id,
		id: data.id
	};

	const {
		isLoading: isGettingActionPlan,
		data: actionPlan,
		isError: hasErrorActionPlan,
		refetch: refetchActionPlan
	} = useGetActionPlan(payload);

	function setInitialValues(): void {
		form.setFieldValue([...fieldName, 'action_plan_name'], actionPlan?.title);
		form.setFieldValue([...fieldName, 'responsible_id'], actionPlan?.responsible_user_id);
		form.setFieldValue([...fieldName, 'due_date'], moment(actionPlan?.due_date));
		form.setFieldValue([...fieldName, 'priority'], actionPlan?.priority);
		form.setFieldValue([...fieldName, 'investment_range'], actionPlan?.investment_range);
		form.setFieldValue([...fieldName, 'investment_value'], actionPlan?.investment_value!);
		form.setFieldValue([...fieldName, 'status'], actionPlan?.status);
		form.setFieldValue([...fieldName, 'description'], actionPlan?.description);
	}

	useEffect(() => {
		if (actionPlan) {
			setInitialValues();
		}
	}, [actionPlan]);

	if (isGettingActionPlan) {
		return <LoadingSkeleton />;
	}

	if (hasErrorActionPlan) {
		return <Refetch onClick={refetchActionPlan} />;
	}

	return (
		<EditActionPlanProvider data={actionPlan}>
			<Row gutter={[0, 30]}>
				<Col span={24}>
					<Name formName={fieldName} />
				</Col>
				<Col span={24}>
					<Row gutter={[45, 25]}>
						<Col span={16} style={{ borderRight: '1px solid #D9D9D9' }}>
							<Row gutter={[0, 25]}>
								<Col span={24}>
									<Details formName={fieldName} />
								</Col>
								<Col span={24}>
									<Attachments
										actionPlanId={data.id}
										companyId={payload.company_id}
										organizationId={payload.organization_id}
									/>
								</Col>
								<Col span={24}>
									<Description formName={fieldName} />
								</Col>
								<Col span={24}>
									<Tasks />
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row gutter={[0, 40]}>
								<Col span={24}>
									<Informations formName={fieldName} />
								</Col>
								<Col span={24}>
									<History />
								</Col>
								<Col span={24}>
									<Comments fieldName={['comments']} actionPlanId={data.id} />
								</Col>
								<Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<DownloadPdf />
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<Footer formName={fieldName} onClose={onClose} />
						</Col>
					</Row>
				</Col>
			</Row>
		</EditActionPlanProvider>
	);
}
