import React from 'react';
import { Col, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import * as S from './styles';
import type { FormFieldProps } from '../../types';
import { InfoTooltip } from './Components/Tooltips/Information';

const { Option } = Select;

export function InvestmentRange({ formName = [''], tooltip = false }: Readonly<FormFieldProps>) {
	const title = I18n.get('Investment');

	const options = [
		{ value: 1, label: <span style={{ fontWeight: '600' }}>$</span> },
		{ value: 2, label: <span style={{ fontWeight: '600' }}>$$</span> },
		{ value: 3, label: <span style={{ fontWeight: '600' }}>$$$</span> }
	];

	return (
		<Row>
			<Col>
				<span style={{ fontSize: '1.1rem' }}>{title}</span>
			</Col>
			<Col>{tooltip && <InfoTooltip title={I18n.get('Expected cost of the action plan')} />}</Col>
			<Col span={24}>
				<S.CustomFormItem name={[...formName, 'investment_range']}>
					<S.CustomSelect allowClear placeholder={title} style={{ marginTop: '13px' }}>
						{options.map((option) => (
							<Option key={option.value} value={option.value}>
								{option.label}
							</Option>
						))}
					</S.CustomSelect>
				</S.CustomFormItem>
			</Col>
		</Row>
	);
}
