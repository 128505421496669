import { I18n } from '@aws-amplify/core';

export const FIELD_CONFIGS = {
	priority: {
		name: 'priority',
		label: I18n.get('Priority'),
		placeholder: I18n.get('Priority'),
		message: I18n.get('Please select a priority')
	},
	due_date: {
		name: 'due_date',
		label: I18n.get('Due Date'),
		placeholder: I18n.get('Date'),
		message: I18n.get('Please select a date')
	},
	responsible_user_id: {
		name: 'responsible_user_id',
		label: I18n.get('Responsible'),
		placeholder: I18n.get('Responsible'),
		message: I18n.get('Please select a responsible')
	}
} as const;
