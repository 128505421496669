import React, { useState } from 'react';
import { Row, Col, Grid } from 'antd';
import { I18n } from '@aws-amplify/core';
import { BellOutlined, PlusCircleFilled } from '@ant-design/icons';

import { Filter } from './Filter';
import { Painel } from './Painel';
import { Sort } from './components/Fields';
import { useActionPlanContext } from './context';
import { DrawerNotifications } from './Notifications';
import { StatusCard } from './components/StatusCard';
import { FilterIcon } from '@/assets/icons/filter-icon';
import { ActionPlansStatus, Panels } from './constants';
import { ModalEdit } from './components/Modals/ModalEdit';
import { ModalCreate } from './components/Modals/ModalCreate';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlansCount } from '@/hooks/v2/useGetActionPlanCount';
import * as S from './styles';

const { useBreakpoint } = Grid;

export function Content() {
	const { xxl } = useBreakpoint();
	const { organization } = useApplicationContext();
	const {
		filter,
		currentPanel,
		onToggleFilter,
		isEditModalOpen,
		onToggleEditModal,
		selectedActionPlan,
		onToggleNotifications
	} = useActionPlanContext();

	const [isOpenModalCreate, setIsOpenModalCreate] = useState<boolean>(false);

	const {
		isLoading: isLoadingToDo,
		data: actionPlansToDo,
		isError: isErrorToDo
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'TO DO',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const {
		isLoading: isLoadingDoing,
		data: actionPlansDoing,
		isError: isErrorDoing
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'DOING',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const {
		isLoading: isLoadingDone,
		data: actionPlansDone,
		isError: isErrorDone
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status: 'DONE',
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	const {
		isLoading: isLoadingTotal,
		data: actionPlansTotal,
		isError: isErrorTotal
	} = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	function handleToggleCreate(): void {
		setIsOpenModalCreate(!isOpenModalCreate);
	}

	if (isErrorToDo || isErrorDoing || isErrorDone || isErrorTotal) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<S.ContainerContent gutter={[0, 12]}>
			<Col span={24}>
				<Row justify="space-between" align="middle">
					<Col span={8}>
						<Row gutter={[8, 0]}>
							<Col>
								<S.IconButton onClick={onToggleFilter}>
									<FilterIcon />
									{!!xxl && I18n.get('Filters')}
								</S.IconButton>
							</Col>
							<Col span={10}>
								<Sort />
							</Col>
							<Col span={6}>
								<S.IconButton onClick={onToggleNotifications}>
									<BellOutlined />
									{!!xxl && I18n.get('Notifications')}
								</S.IconButton>
							</Col>
						</Row>
					</Col>
					<Col span={16}>
						<Row gutter={[8, 0]} justify="end">
							<Col>
								<StatusCard isLoading={isLoadingTotal} value={actionPlansTotal?.total} />
							</Col>
							{currentPanel === Panels.TABLE && (
								<>
									<Col>
										<StatusCard
											isLoading={isLoadingToDo}
											status={ActionPlansStatus.TO_DO}
											value={actionPlansToDo?.total}
										/>
									</Col>
									<Col>
										<StatusCard
											isLoading={isLoadingDoing}
											status={ActionPlansStatus.DOING}
											value={actionPlansDoing?.total}
										/>
									</Col>
									<Col>
										<StatusCard
											isLoading={isLoadingDone}
											status={ActionPlansStatus.DONE}
											value={actionPlansDone?.total}
										/>
									</Col>
								</>
							)}
							<Col>
								<S.PrimaryIconButton
									type="primary"
									icon={<PlusCircleFilled />}
									onClick={handleToggleCreate}
								>
									{I18n.get('New action plan')}
								</S.PrimaryIconButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Painel />
			</Col>
			<Filter />
			<DrawerNotifications />
			<ModalCreate open={isOpenModalCreate} onClose={handleToggleCreate} />
			<ModalEdit isOpen={isEditModalOpen} onClose={onToggleEditModal} data={selectedActionPlan} />
		</S.ContainerContent>
	);
}
