import React from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import * as S from './styles';
import type { FormFieldProps } from './types';
import { DollarSignIcon } from '@/assets/icons/dollar-sign';
import { InfoTooltip } from './components/Tooltips/Information';

const { useWatch, useFormInstance } = Form;

const options = [
	{
		value: 1,
		icon: <DollarSignIcon />
	},
	{
		value: 2,
		icon: (
			<S.IconsWrapper>
				<DollarSignIcon />
				<DollarSignIcon />
			</S.IconsWrapper>
		)
	},
	{
		value: 3,
		icon: (
			<S.IconsWrapper>
				<DollarSignIcon />
				<DollarSignIcon />
				<DollarSignIcon />
			</S.IconsWrapper>
		)
	}
];

export function InvestmentRange({ formName = [''] }: Readonly<FormFieldProps>) {
	const form = useFormInstance();

	const title = I18n.get('Investment');
	const fieldName = [...formName, 'investment_range'];

	const investmentRange = useWatch(fieldName);

	function handleOnClick(value: number): void {
		const isChecked = value === investmentRange;

		if (isChecked) {
			form.setFieldValue(fieldName, undefined);
		}
	}

	return (
		<Row>
			<Col>
				<span style={{ fontSize: '1.1rem' }}>{title}</span>
			</Col>
			<Col span={12}>
				<InfoTooltip title={I18n.get('Expected cost of the action plan')} />
			</Col>
			<Col span={24}>
				<S.CustomFormItem name={fieldName}>
					<S.Group>
						<Row gutter={[16, 0]} style={{ marginTop: '13px' }}>
							{options.map(({ value, icon }, index) => (
								<Col key={index} span={8}>
									<S.RadioButton onClick={() => handleOnClick(value)} value={value}>
										{icon}
									</S.RadioButton>
								</Col>
							))}
						</Row>
					</S.Group>
				</S.CustomFormItem>
			</Col>
		</Row>
	);
}
