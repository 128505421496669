import React from 'react';
import { Row, Col, Spin } from 'antd';
import { Title } from '@/components/Typography';
import * as S from './styles';

type HeaderProps = {
	isLoading?: boolean;
	title?: string;
	total?: number;
	color?: string;
};

export function Header({ isLoading, title = '', total = 0, color = '' }: Readonly<HeaderProps>) {
	return (
		<Row align="middle">
			<Col>
				<Title level={5} style={{ margin: 0 }}>
					{title}
				</Title>
			</Col>
			<Col>
				{isLoading && <Spin />}
				<S.Totalizer color={color}>{total}</S.Totalizer>
			</Col>
		</Row>
	);
}
