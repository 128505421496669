import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';

import * as S from './styles';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useActionPlanContext } from '@/views/ActionPlans/context';
import { useCreateActionPlan } from '@/hooks/v2/useCreateActionPlan';
import { ActionPlanCreateMapper } from '@/core/mapper/action-plan-create';

const { useFormInstance } = Form;

interface FooterProps {
	formName: string[];
	onClose(): void;
}

export function Footer({ formName, onClose }: Readonly<FooterProps>) {
	const form = useFormInstance();
	const queryClient = useQueryClient();
	const { onToggleEditModal, selectActionPlan } = useActionPlanContext();
	const { isLoading, mutateAsync: createActionPlan } = useCreateActionPlan();

	async function handleOnSubmit(): Promise<void> {
		try {
			const formValues = await form.validateFields();
			const values = formValues[formName[0]];
			const payload = ActionPlanCreateMapper.toDTO(values);
			const { data } = await createActionPlan(payload);

			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_LIST]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_COUNT]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST]);
			onClose();

			selectActionPlan({ id: data.id, company_id: data.company_id });
			onToggleEditModal();
		} catch (error: any) {
			if (!error?.errorFields) {
				const errorMessage = error?.response?.data.message || error.message;
				message.error(I18n.get(errorMessage));
			}
		}
	}

	return (
		<Row justify="center" gutter={[30, 0]}>
			<Col>
				<S.SecondaryButton onClick={onClose}>{I18n.get('Cancel')}</S.SecondaryButton>
			</Col>
			<Col>
				<S.PrimaryButton type="primary" loading={isLoading} onClick={handleOnSubmit}>
					{I18n.get('Save')}
				</S.PrimaryButton>
			</Col>
		</Row>
	);
}
