import styled, { keyframes } from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Select, Typography } from 'antd';

export const DANGER_COLOR = '#E74150';
export const DEFAULT_COLOR = '#2F54EB';

export const StyledSelect = styled(Select)`
	.ant-select-selection-placeholder {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: left;
	}
`;

export const DatePickerWrapper = styled(DatePicker)`
	width: 100%;
	.ant-picker-input > input:placeholder-shown {
		font-size: 16px;
	}
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
	color: #e74150;
	font-size: 4.2rem;
	text-align: center;
`;

export const DeleteTypography = styled(Typography)`
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	padding-top: 20px;
`;

const fadeIn = keyframes`
	from {
    	opacity: 0;
    	transform: translateY(20px);
  	}
  	to {
    	opacity: 1;
    	transform: translateY(0);
  	}
`;

export const CardWrapper = styled(Card)`
	left: 50%;
	bottom: 16px;
	padding: 3px;
	z-index: 1000;
	position: fixed;
	border-radius: 6px;
	transform: translateX(-50%);
	box-shadow: 2px 2px 10px 2px #00000040;

	.ant-card-body {
		margin: 0;
		padding: 0;
		display: flex;
		animation: ${fadeIn} 0.3s ease-out;
	}
`;

export const StyledButton = styled(Button)`
	border: none;
	display: flex;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	padding: 6.4px 10px;
`;

export const StyledIconEdit = styled.span<{ color?: string }>`
	color: ${({ color }) => color || DEFAULT_COLOR};
	font-size: 22px;
`;
