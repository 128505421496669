import styled from 'styled-components';
import { Button, Card, Skeleton } from 'antd';

export const InfoCard = styled.div`
	background-color: #f5f5f5;
	padding: 16px;
	border-radius: 8px;
`;

export const InfoLabel = styled.span`
	font-weight: 600;
	font-size: 18px;
`;

export const InfoValue = styled.span`
	margin-left: 8px;
	font-size: 18px;
`;

export const Scrollbar = styled.div`
	overflow: auto;

	::-webkit-scrollbar {
		width: 5px;
		max-height: 100px;
	}
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	::-webkit-scrollbar-thumb {
		background-color: #9c9c9c;
		border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const HistoryContainer = styled.div`
	border-bottom: 1px solid #f0f0f0;
`;

export const TitleHistory = styled.p`
	font-weight: 600;
	font-size: 16px;
	color: #000;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 14px;
`;

export const SubtitleHistory = styled.p`
	color: #8c8c8c;
	font-size: 16px;
	margin: 0;
	margin-bottom: 14px;
`;

export const DateText = styled.span`
	color: #262626;
	font-size: 16px;
`;

export const CommentsCard = styled(Card)`
	border: 1px solid #d9d9d9;
	border-radius: 12px;
	margin-bottom: 10px;

	.ant-card-body {
		padding: 8px 16px;
	}
`;
export const CommentTitle = styled.h3`
	margin-bottom: 8px;
	font-weight: 600;
`;
export const CommentText = styled.p`
	margin-bottom: 8px;
`;
export const CommentAuthor = styled.p`
	font-size: 16px;
	color: #888;
	margin-bottom: 0px;
`;
export const CommentDate = styled.p`
	font-size: 16px;
	color: #888;
	margin-bottom: 0px;
`;

export const TitleButton = styled(Button)<{ iseditingtitle?: 'true' | 'false' }>`
	margin-left: 10px;
	background-color: #2f54eb;
	display: ${({ iseditingtitle }) => (iseditingtitle === 'true' ? 'block' : 'none')};
`;

export const HistoryWrapper = styled(Scrollbar)`
	max-height: 14rem;
	padding-right: 1rem;
`;

export const CustomSkeleton = styled(Skeleton.Input)`
	width: 100%;
	border-radius: 6px;

	.ant-skeleton-input {
		width: 100%;
	}
`;
