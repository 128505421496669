import styled from 'styled-components';
import { Upload, Col, Row, Modal, Button } from 'antd';

export const COLOR_ICON_DANGER = 'red';
export const COLOR_ICON_ENABLED = 'blue';
export const COLOR_ICON_DISABLED = 'gray';

export const StyledRowCenter = styled(Row)`
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
`;

export const StyledPreviewIcon = styled.div`
	text-align: center;
	padding: 20px;

	.file-icon {
		font-size: 64px;
		color: #070707;
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;

export const ModalPreviewImageWrapper = styled(Modal)`
	.ant-modal-body {
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ant-modal-content {
		background-color: transparent;
		box-shadow: none;
	}

	.ant-modal-close-x {
		display: none;
	}
`;

export const ModalCommentWrapper = styled(Modal)`
	.ant-modal-body {
		padding: 2rem 3rem;
	}

	.ant-modal-content {
		border-radius: 30px;
		background-color: #ffffff;
	}

	.ant-modal-close-x {
		margin: 0.7rem 0.7rem 0 0;
		font-size: 0.9rem;
	}
`;

export const StyledUpload = styled(Upload)`
	margin-bottom: 4rem;

	.responsive-image {
		width: 100%;
		height: auto;
		max-width: 300px;
		object-fit: contain;
	}

	.ant-upload-list {
		margin-left: -5%;
		flex-wrap: wrap;
		display: flex;
		gap: 5%;
		position: relative;
	}

	.ant-upload-list-picture-card-container {
		height: 140px;
		width: 140px;
		margin: 0;

		&.ant-upload-list-item-removed {
			display: none !important;
			position: absolute !important;
			visibility: hidden !important;
			width: 0 !important;
			height: 0 !important;
			margin: 0 !important;
			padding: 0 !important;
			border: 0 !important;
		}
	}

	.ant-upload-list-item-progress {
		width: calc(100% - 50px);
		margin-bottom: 20%;

		.ant-progress-bg {
			height: 10px !important;
		}

		.ant-progress-inner {
			background-color: rgba(217, 217, 217, 1);
		}

		.ant-progress-line {
			width: 100%;
		}

		.ant-progress-bg {
			background-color: rgba(47, 84, 235, 1);
		}
	}

	.ant-upload-span {
		div {
			display: none;
		}
	}

	.ant-progress-bg {
		height: 10px;
	}

	.ant-upload-list-item {
		background-color: rgba(248, 248, 248, 1);
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		display: flex;
		border: none;
		height: 100%;
		width: 100%;
		padding: 0;
	}

	.ant-upload-list-item-thumbnail {
		width: 100%;
		height: 100%;
	}

	.ant-upload-list-item-thumbnail img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.custom-upload-item {
		position: relative;
		height: 100%;
		width: 100%;
	}

	.custom-upload-infos,
	.custom-upload-actions {
		transition: opacity 0.3s ease;
		justify-content: center;
		align-items: center;
		position: absolute;
		display: flex;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}

	.custom-upload-infos {
		background-color: rgba(230, 230, 230, 0.9);
		z-index: 2;
		opacity: 1;
	}

	.custom-upload-actions {
		background-color: rgba(255, 255, 255, 0.95);
		z-index: 1;
		opacity: 0;
	}

	.custom-upload-item:hover .custom-upload-actions {
		opacity: 1;
	}

	.custom-upload-actions .anticon {
		transition: transform 0.2s ease;
		font-size: 1.8rem;
		margin: 0 0.5rem;
		color: #1890ff;
	}

	.custom-upload-actions .anticon:hover {
		transform: scale(1.1);
		cursor: pointer;
	}

	.ant-upload-list-item-actions {
		display: none;
	}

	.ant-upload-list-picture-card .ant-upload-list-item-info::before {
		background-color: transparent;
	}

	.ant-upload-animate-enter,
	.ant-upload-animate-leave,
	.ant-upload-animate-inline-enter,
	.ant-upload-animate-inline-leave,
	.ant-upload-list-item-removed {
		animation: none !important;
		transition: none !important;
		display: none !important;
	}
`;

export const StyledFileIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: #f0f0f0;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;

	.file-icon {
		font-size: 50px;
		color: rgba(38, 38, 38, 1) !important;
	}
`;

export const StyledRow = styled(Row)`
	width: 100%;
	height: 100%;
`;

export const StyledCol = styled(Col)`
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 0;

	&:nth-child(1) {
		justify-content: right;
		padding-top: 1.5rem;
	}
	&:nth-child(2) {
		justify-content: left;
		padding-top: 1.5rem;
	}
	&:nth-child(3) {
		justify-content: right;
		padding-bottom: 1.5rem;
	}
	&:nth-child(4) {
		justify-content: left;
		padding-bottom: 1.5rem;
	}
`;

export const IconWrapper = styled.div`
	transition: all 0.3s ease;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 40px;
	width: 40px;

	&:hover {
		transform: scale(1.1);
	}

	.anticon {
		font-size: 20px;
	}
`;

export const StyledFilePreview = styled.div`
	background-color: rgba(230, 230, 230, 1);
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	padding: 10px;
	margin: 0;

	p {
		margin: 0;
		&:nth-child(2) {
			color: rgba(38, 38, 38, 0.6);
		}
	}
`;

export const StyledFileInfosOverlay = styled.div`
	background-color: rgba(230, 230, 230, 1);
	margin-bottom: 0.3rem;

	.custom-upload-infos-overlay-name {
		font-size: 1rem;
	}

	p {
		margin: 0;
		span {
			color: rgba(38, 38, 38, 0.6);
		}
		&:nth-child(2) {
			color: rgba(38, 38, 38, 0.6);
		}
	}
`;

export const StyledInfoOverlay = styled.div<{ visible: boolean }>`
	display: ${({ visible }) => (visible ? 'flex' : 'none')};
	background-color: rgba(230, 230, 230, 1);
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
	flex-direction: column;
	position: absolute;
	overflow-y: auto;
	padding: 10px;
	z-index: 2;
	bottom: 0;
	right: 0;
	left: 0;
	top: 0;
`;

export const StyledCloseButton = styled.button`
	position: absolute;
	background: none;
	font-size: 16px;
	cursor: pointer;
	border: none;
	color: #333;
	right: 5px;
	top: 5px;
`;

export const ResponsiveImage = styled.img`
	width: 100%;
	height: 100%;
	max-width: 300px;
	max-height: 300px;
	object-fit: contain;
	background-color: white;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`;

export const StyledButton = styled.button`
	height: 100%;
	width: 140px;
	cursor: pointer;
	border-radius: 12px;
	background-color: rgba(248, 248, 248, 1);
	border: 1px solid rgba(217, 217, 217, 1);
`;
