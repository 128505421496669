import React from 'react';
import { I18n } from '@aws-amplify/core';
import { RiskLabel, RiskContainer } from './styles';

type RiskScore = {
	score?: number;
};

export function RiskEvaluation({ score = 0 }: Readonly<RiskScore>) {
	return (
		<>
			<RiskLabel>{I18n.get('Risk')}</RiskLabel>
			<RiskContainer $score={score}>
				<span>RPN: {score}</span>
			</RiskContainer>
		</>
	);
}
