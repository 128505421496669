import * as React from 'react';

interface BlueFlagProps {
	width?: string;
	height?: string;
}

export const BlueFlag = ({ width = '1.1rem', height = '1.1rem' }: Readonly<BlueFlagProps>) => {
	return (
		<svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 23L1.00001 1" stroke="#2F54EB" stroke-width="3" stroke-linecap="round" />
			<path
				d="M1 2.62869C1 2.62869 3.89921 3.57122 5.81879 3.72176C10.0721 4.05533 12.1992 0.573705 16.4408 1.04373C18.5942 1.28234 21.7778 2.62869 21.7778 2.62869V14.4444C21.7778 14.4444 19.0208 13.0186 17.1144 12.6409C12.6836 11.763 10.2936 15.734 5.81879 15.1549C3.88463 14.9047 1 13.7886 1 13.7886"
				stroke="#2F54EB"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
