import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Collapse } from 'antd';

import { TableListDescription, TableDescription, DateComponent, RowCustomer, TableTitle, RpnScore } from './styles';
import { Text, Title } from '@/components/Typography';
import { riskLevelToColorMapper } from '../types';
import { PanelCustom } from '../Tools/styles';
import { NOT_APPLICABLE } from '@/constants';
import { ActionPlan } from '@/hooks';
import { Task } from './Task';

interface ActionPlansProps {
	data: ActionPlan[];
}

export function ActionPlans({ data }: ActionPlansProps) {
	return (
		<Collapse ghost>
			<PanelCustom header={I18n.get('Action plans')} key={'action-plans'}>
				<Row gutter={[0, 30]}>
					{data?.map(({ id, title, score, risk_score, responsible, deadline, description, tasks }) => {
						return (
							<Col key={id} span={24}>
								<RowCustomer justify="center">
									<Col span={24}>
										<Row justify="space-between">
											<Col span={20}>
												<TableTitle>
													<Title level={5}>{title}</Title>
												</TableTitle>
											</Col>
											<Col span={4}>
												<RpnScore
													$riskColor={riskLevelToColorMapper[risk_score]}
													justify="start"
												>
													<Col>RPN</Col>
													<Col span={24}>{score}</Col>
												</RpnScore>
											</Col>
										</Row>
										<Row>
											<Col span={12}>
												<Row>
													<TableTitle span={24} $hasColor>
														<Text>Responsible</Text>
													</TableTitle>
													<TableDescription>{responsible}</TableDescription>
												</Row>
												<Row>
													<TableTitle span={24} $hasColor>
														<Text>Deadline</Text>
													</TableTitle>
													<TableDescription span={24}>
														<Row justify="space-between" align="middle">
															<Col xl={11} xxl={12}>
																{deadline.remaining_days}{' '}
																<Text>{deadline.remaining_days_text}</Text>
															</Col>
															<Col>
																<DateComponent>
																	<Row>
																		<Col>{deadline.day}</Col>
																		<Col>{deadline.month}</Col>
																		<Col>{deadline.year}</Col>
																	</Row>
																</DateComponent>
															</Col>
														</Row>
													</TableDescription>
												</Row>
											</Col>
											<Col span={12}>
												<Row>
													<TableTitle span={24} $hasColor $hasBorder>
														<Text>Description</Text>
													</TableTitle>
												</Row>
												<Row style={{ height: '100%' }}>
													<TableDescription span={24} $customPadding="20px 38px" $hasBorder>
														{description}
													</TableDescription>
												</Row>
											</Col>
										</Row>
										<Row>
											<Col span={24}>
												<Row justify="center">
													<TableTitle $hasColor span={24}>
														<Text>Tasks</Text>
													</TableTitle>
													<TableListDescription lg={24} xl={22}>
														{tasks?.length > 0 && (
															<Row align="middle" justify="space-between">
																<Col
																	span={9}
																	style={{ textAlign: 'center', fontWeight: 'bold' }}
																>
																	{I18n.get('Name')}
																</Col>
																<Col
																	span={8}
																	style={{ textAlign: 'center', fontWeight: 'bold' }}
																>
																	{I18n.get('Responsible')}
																</Col>
																<Col
																	span={7}
																	style={{ textAlign: 'center', fontWeight: 'bold' }}
																>
																	{I18n.get('Deadline')}
																</Col>
															</Row>
														)}
														{tasks?.length > 0 ? (
															tasks?.map((task) => <Task key={task.id} task={task} />)
														) : (
															<Row>{NOT_APPLICABLE}</Row>
														)}
													</TableListDescription>
												</Row>
											</Col>
										</Row>
									</Col>
								</RowCustomer>
							</Col>
						);
					})}
				</Row>
			</PanelCustom>
		</Collapse>
	);
}
