import React, { useMemo } from 'react';
import moment from 'moment';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DefaultOptionType } from 'antd/lib/select';

import * as S from './styles';
import { FIELD_CONFIGS } from './config';
import { ModalEditProps } from './types';
import { ModalWrapper } from './ModalWrapper';
import { Text, Title } from '@/components/Typography';
import { CustomButton } from '@/components/ui/Buttons/CustomButton/styles';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { InfoTooltip } from '../../components/Fields/Components/Tooltips/Information';

const PRIORITY_OPTIONS = [
	{ label: <Text>Low</Text>, value: 1 },
	{ label: <Text>Medium</Text>, value: 2 },
	{ label: <Text>High</Text>, value: 3 }
];

export const MODAL_TITLE_CONFIG = {
	priority: I18n.get('Priority editing'),
	due_date: I18n.get('Due Date editing'),
	responsible_user_id: I18n.get('Responsible editing'),
	delete: ''
} as const;

export function ModalEdit({
	form,
	users,
	handleOk,
	isLoading,
	selectType,
	isModalOpen,
	handleOnCancel,
	handleOnSubmit,
	handleOnDelete,
	handleSelectChange
}: Readonly<ModalEditProps>) {
	const selectOptions = useMemo<DefaultOptionType[]>(() => {
		if (!selectType || selectType === 'delete' || selectType === 'due_date') {
			return [];
		}

		if (selectType === 'responsible_user_id') {
			const user_options = users.map((user) => {
				return {
					...user,
					label: (
						<>
							<UserAvatar name={user.label as string} size="small" style={{ marginRight: '10px' }} />
							<Text>{user.label}</Text>
						</>
					)
				};
			});

			return user_options;
		}

		return PRIORITY_OPTIONS;
	}, [selectType, users]);

	function getModalTitle(): string {
		return selectType ? MODAL_TITLE_CONFIG[selectType] || '' : '';
	}

	function renderFormField() {
		if (!selectType || selectType === 'delete') return null;

		const field = FIELD_CONFIGS[selectType];
		if (!field) return null;

		const isDateField = selectType === 'due_date';
		return (
			<Col sm={24}>
				<Row>
					<Col>
						<span style={{ fontSize: '1.1rem' }}>{I18n.get(field.label)}</span>
					</Col>
					<Col>
						{selectType === 'priority' ? (
							<InfoTooltip title={I18n.get('Categorization of the urgency of action plan resolution')} />
						) : (
							<span style={{ fontSize: '1.1rem', color: 'red' }}>*</span>
						)}
					</Col>
					<Col span={24}>
						<Form.Item
							name={field.name}
							style={{ width: '65%', marginTop: '13px' }}
							rules={[{ required: true, message: I18n.get(field.message) }]}
						>
							{isDateField ? (
								<S.DatePickerWrapper
									placeholder={I18n.get(field.placeholder)}
									format={moment().locale(navigator.language).localeData().longDateFormat('L')}
								/>
							) : (
								<S.StyledSelect
									allowClear
									loading={isLoading}
									options={selectOptions}
									style={{ fontSize: '1.1rem' }}
									placeholder={I18n.get(field.placeholder)}
									onChange={(value) => handleSelectChange(value as string | undefined)}
								/>
							)}
						</Form.Item>
					</Col>
				</Row>
			</Col>
		);
	}

	function renderActionButtons() {
		const isDeleteAction = selectType === 'delete';

		return (
			<Row justify="center" gutter={[12, 0]}>
				<Col>
					<CustomButton key="cancel" onClick={handleOnCancel}>
						{I18n.get('Cancel')}
					</CustomButton>
				</Col>
				<Col>
					<CustomButton
						type="primary"
						key={isDeleteAction ? 'delete' : 'submit'}
						htmlType={isDeleteAction ? 'button' : 'submit'}
						onClick={isDeleteAction ? handleOnDelete : handleOnSubmit}
					>
						{I18n.get(isDeleteAction ? 'Yes' : 'Save')}
					</CustomButton>
				</Col>
			</Row>
		);
	}

	return (
		<ModalWrapper open={isModalOpen} onCancel={handleOnCancel} onOk={() => handleOk(null)}>
			<Form form={form} layout="vertical" style={{ width: '100%', padding: '10px' }}>
				<Row justify="center">
					<Col sm={24} style={{ paddingBottom: '5px' }}>
						<Title level={3}>{getModalTitle()}</Title>
						{selectType === 'delete' && (
							<Row justify="center" style={{ paddingBottom: '1.5rem' }}>
								<Col span={24} style={{ textAlign: 'center' }}>
									<S.StyledDeleteIcon />
								</Col>
								<Col span={24}>
									<S.DeleteTypography>
										{I18n.get('Are you sure you want to delete the selected items?')}
									</S.DeleteTypography>
								</Col>
							</Row>
						)}
					</Col>
					{renderFormField()}
					<Col sm={24} style={{ marginTop: '15px' }}>
						<Form.Item shouldUpdate>{renderActionButtons}</Form.Item>
					</Col>
				</Row>
			</Form>
		</ModalWrapper>
	);
}
