import React from 'react';
import { Col, Grid, Row, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './styles';
import { Text } from '@/components/Typography';
import { ActionPlansStatus } from '../../constants';
import { useQueryParams } from '@/hooks/v2/useQueryParams';

const { useBreakpoint } = Grid;

const DEFAULT_TITLE = 'Total';

const titleMapper: Record<string, string> = {
	[ActionPlansStatus.TO_DO]: 'To Do',
	[ActionPlansStatus.DOING]: 'Doing',
	[ActionPlansStatus.DONE]: 'Done'
};

interface StatusCardProps {
	status?: string;
	value?: number;
	isLoading: boolean;
}

export function StatusCard({ status = '', value = 0, isLoading }: Readonly<StatusCardProps>) {
	const { xxl } = useBreakpoint();
	const query = useQueryParams();
	const location = useLocation();
	const history = useHistory();

	const title = titleMapper[status] || DEFAULT_TITLE;

	function shouldRenderTitle(): boolean {
		return !!xxl || title === DEFAULT_TITLE;
	}

	function handleFilter() {
		status === '' ? query.delete('status') : query.set('status', status);
		replaceUrlParams();
	}

	function replaceUrlParams(): void {
		history.replace({
			pathname: location.pathname,
			search: query.toString()
		});
	}

	return (
		<S.FilterButton $status={status} onClick={handleFilter}>
			<Row>
				{shouldRenderTitle() && (
					<Col style={{ marginRight: 10 }}>
						<Text>{title}</Text>:
					</Col>
				)}
				<Col>{isLoading ? <Spin /> : <span className="status-value">{value}</span>}</Col>
			</Row>
		</S.FilterButton>
	);
}
