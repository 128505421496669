import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { MoreOutlined } from '@ant-design/icons';
import { Row, Col, Dropdown, Space, Button } from 'antd';

import { CustomTextArea } from '../../../Fields/styles';
import { CustomFormItem } from '@/components/CustomReports/styles';
import { ActionPlanComments } from '@/core/domain/action-plan-comments';
import { PrimaryButton, SecondaryButton } from '@/views/ActionPlans/styles';
import { CommentAuthor, CommentDate, CommentsCard, CommentTitle } from '../styles';

interface EditingComment {
	commentId: string;
	text: string;
}

interface CommentItemProps {
	index: number;
	fieldName: string[];
	comment: ActionPlanComments;
	editingComment: { commentId: string; text: string } | null;
	setEditingComment: React.Dispatch<React.SetStateAction<EditingComment | null>>;
	handleSaveComment: (index: number) => void;
	getMenuItems: (commentId: string) => any[];
}

export function CommentItem({
	comment,
	index,
	editingComment,
	setEditingComment,
	handleSaveComment,
	getMenuItems,
	fieldName
}: Readonly<CommentItemProps>) {
	return (
		<CommentsCard key={index}>
			<Row>
				<Col span={23}>
					<div
						style={{
							display: editingComment?.commentId === comment.id ? 'block' : 'none'
						}}
					>
						<CustomFormItem labelCol={{ span: 24 }} name={[...fieldName, 'commentsEdit']}>
							<>
								<CustomTextArea
									maxLength={500}
									rows={1}
									showCount
									value={
										editingComment?.commentId === comment.id
											? editingComment.text
											: comment.description
									}
									onChange={(e) =>
										setEditingComment((prev) =>
											prev
												? {
														...prev,
														text: e.target.value
												  }
												: null
										)
									}
								/>
							</>
						</CustomFormItem>
						<Row gutter={[20, 0]} style={{ marginTop: '20px', marginBottom: '10px' }}>
							<Col>
								<SecondaryButton onClick={() => setEditingComment(null)}>
									{I18n.get('Cancel')}
								</SecondaryButton>
							</Col>
							<Col>
								<PrimaryButton type="primary" onClick={() => handleSaveComment(index)}>
									{I18n.get('Save')}
								</PrimaryButton>
							</Col>
						</Row>
					</div>
					<div
						style={{
							display: editingComment?.commentId === comment.id ? 'none' : 'block'
						}}
					>
						<CommentTitle>{comment.description}</CommentTitle>{' '}
					</div>
				</Col>
				<Col span={1}>
					<Dropdown menu={{ items: getMenuItems(comment.id) }} trigger={['click']}>
						<Space>
							<Button
								type="text"
								size={'small'}
								icon={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
							/>
						</Space>
					</Dropdown>
				</Col>
			</Row>
			<Row
				style={{
					display: editingComment?.commentId === comment.id ? 'none' : 'block'
				}}
			>
				<Col>
					<Row>
						<Col sm={24} xl={17}>
							<CommentAuthor>Por {comment.user.name}</CommentAuthor>{' '}
						</Col>
						<Col sm={24} xl={7}>
							<CommentDate>{moment(comment.updated_at).format('L')}</CommentDate>{' '}
						</Col>
					</Row>
				</Col>
			</Row>
		</CommentsCard>
	);
}
